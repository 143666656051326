<template>
  <v-select
    v-model="selectedProducts"
    :items="products"
    :label="$trans('simple_store_products')"
    :loading="isLoading"
    multiple
    small-chips
    item-text="name"
    item-value="id"
    :dense="dense"
    outlined
    :height="selectedProducts && selectedProducts.length > 1 ? null : height"
    :disabled="disabled"
    hide-details="auto"
    clearable
    deletable-chips
    :rules="rules"
    @change="$emit('input', selectedProducts)"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SimpleStoreProductField",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      selectedProducts: this.value,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      products: "simpleStore/get",
    }),
  },
  watch: {
    value(val) {
      if (!val || val.length === 0) {
        this.selectedProducts = [];
      }
    },
  },
  created() {
    if (!this.products || this.products.length === 0) {
      this.isLoading = true;
      this.fetchAllProducts().finally(() => {
        this.isLoading = false;
      });
    }
  },
  methods: {
    ...mapActions({
      fetchAllProducts: "simpleStore/fetchAllProducts",
    }),
  },
};
</script>
