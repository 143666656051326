<template>
  <v-container>
    <v-form ref="form" @submit.prevent="handleSave">
      <v-container fluid>
        <v-row
          v-if="user && selectedProductIds && selectedProductIds.length > 0"
        >
          <v-col
            v-if="!showFakturowniaWarning && !showCustomerDataInvoiceWarning"
            cols="12"
          >
            <calendesk-information-message>
              {{ $trans("create_payment_invoice_info") }}
            </calendesk-information-message>
          </v-col>
          <v-col v-else-if="showFakturowniaWarning" class="pt-0 mt-0" cols="12">
            <calendesk-information-message
              color="orange"
              additional-class="white--text"
              icon-color="white"
              icon="$alert"
            >
              {{ $trans("invoice_warning_fakturownia_disabled") }}
            </calendesk-information-message>
          </v-col>
          <v-col
            v-else-if="showCustomerDataInvoiceWarning"
            class="pt-0 mt-0"
            cols="12"
          >
            <calendesk-information-message
              color="orange"
              additional-class="white--text"
              icon-color="white"
              icon="$alert"
            >
              {{ $trans("invoice_warning_client_data") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <simple-store-product-field
              v-model="selectedProductIds"
              :disabled="isLoading"
              :rules="[rules.required, rules.requiredArray]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <clients-field
              v-model="user"
              :disabled="isLoading"
              :rules="[rules.required]"
              chips
              deletable-chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="paymentMethod"
              :disabled="isLoading"
              :items="paymentMethods"
              hide-details
              :label="$trans('payment_method')"
              outlined
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="transactionId"
              :disabled="isLoading"
              hide-details
              :rules="[rules.maxChars(255)]"
              :label="$trans('payment_transaction_transaction_id')"
              outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  maxChars,
  maxNumber,
  requiredArray,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  canIssueInvoiceForUser,
  paymentMethodsForSelect,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import SimpleStoreProductField from "@/components/Forms/SimpleStoreProductField";
import ClientsField from "@/views/dashboard/pages/Calendar/components/forms/Booking/ClientsField.vue";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "CreateSimpleStoreProductPaymentTransactionDialog",
  components: {
    ClientsField,
    SimpleStoreProductField,
    CalendeskInformationMessage,
  },
  mixins: [planActions],
  data() {
    return {
      paymentMethod: this.$helpers.paymentMethods.CASH,
      paymentMethods: paymentMethodsForSelect(false),
      transactionId: null,
      user: null,
      selectedProductIds: [],
      isLoading: false,
      rules: {
        required,
        maxChars,
        maxNumber,
        requiredArray,
      },
    };
  },
  computed: {
    ...mapGetters({
      products: "simpleStore/get",
    }),
    anyProductWantsInvoice() {
      if (this.selectedProductIds) {
        // Iterate over the selectedProductIds array
        for (let selectedProductId of this.selectedProductIds) {
          // Find the product in the products array
          const product = this.products.find((p) => p.id === selectedProductId);

          // Check if the product has the wants_invoice flag set to true
          if (product && product.wants_invoice) {
            return true;
          }
        }
      }

      return false;
    },
    showCustomerDataInvoiceWarning() {
      return (
        this.anyProductWantsInvoice &&
        (!this.user || !canIssueInvoiceForUser(this.user))
      );
    },
    showFakturowniaWarning() {
      return this.anyProductWantsInvoice && !this.isFakturowniaEnabled;
    },
  },
  methods: {
    ...mapActions({
      create: "simpleStore/createPaymentTransaction",
      refreshSimpleStoreTransactionList: "simpleStore/refreshTransactionList",
      fetchStats: "dashboard/fetchStats",
    }),
    handleSave() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    async add() {
      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);
      try {
        pushEvent("createSimpleStoreProductPaymentTransaction");

        await this.create({
          simple_store_product_ids: this.selectedProductIds,
          user_id: this.user.id,
          payment_method: this.paymentMethod,
          transaction_id: this.transactionId,
        });

        await this.fetchStats().then(() => {
          this.refreshSimpleStoreTransactionList();
          this.$emit("close");
        });
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("ctaButtonLoading", false);
      }
    },
  },
};
</script>
